.flatpickr-calendar .flatpickr-day {
  color: #6e6b7b;
}
[dir] .flatpickr-calendar .flatpickr-day.today {
  border-color: #0875d6;
}
.flatpickr-calendar .flatpickr-day.today:hover {
  color: #6e6b7b;
}
[dir] .flatpickr-calendar .flatpickr-day.today:hover {
  background: transparent;
}
.flatpickr-calendar .flatpickr-day.selected, .flatpickr-calendar .flatpickr-day.selected:hover {
  color: #fff;
}
[dir] .flatpickr-calendar .flatpickr-day.selected, [dir] .flatpickr-calendar .flatpickr-day.selected:hover {
  background: #0875d6;
  border-color: #0875d6;
}
[dir] .flatpickr-calendar .flatpickr-day.inRange, [dir] .flatpickr-calendar .flatpickr-day.inRange:hover {
  background: #7dbffa;
  border-color: #7dbffa;
}
[dir=ltr] .flatpickr-calendar .flatpickr-day.inRange, [dir=ltr] .flatpickr-calendar .flatpickr-day.inRange:hover {
  box-shadow: -5px 0 0 #7dbffa, 5px 0 0 #7dbffa;
}
[dir=rtl] .flatpickr-calendar .flatpickr-day.inRange, [dir=rtl] .flatpickr-calendar .flatpickr-day.inRange:hover {
  box-shadow: 5px 0 0 #7dbffa, -5px 0 0 #7dbffa;
}
.flatpickr-calendar .flatpickr-day.startRange, .flatpickr-calendar .flatpickr-day.endRange, .flatpickr-calendar .flatpickr-day.startRange:hover, .flatpickr-calendar .flatpickr-day.endRange:hover {
  color: #fff;
}
[dir] .flatpickr-calendar .flatpickr-day.startRange, [dir] .flatpickr-calendar .flatpickr-day.endRange, [dir] .flatpickr-calendar .flatpickr-day.startRange:hover, [dir] .flatpickr-calendar .flatpickr-day.endRange:hover {
  background: #0875d6;
  border-color: #0875d6;
}
[dir=ltr] .flatpickr-calendar .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), [dir=ltr] .flatpickr-calendar .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), [dir=ltr] .flatpickr-calendar .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #0875d6;
}
[dir=rtl] .flatpickr-calendar .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), [dir=rtl] .flatpickr-calendar .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), [dir=rtl] .flatpickr-calendar .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: 10px 0 0 #0875d6;
}
.flatpickr-calendar .flatpickr-day.flatpickr-disabled, .flatpickr-calendar .flatpickr-day.prevMonthDay, .flatpickr-calendar .flatpickr-day.nextMonthDay {
  color: #dae1e7;
}
[dir] .flatpickr-calendar .flatpickr-day:hover {
  background: #f6f6f6;
}
.flatpickr-calendar:after, .flatpickr-calendar:before {
  display: none;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month {
  top: -5px;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover i, .flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover i,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #0875d6;
}
.flatpickr-calendar .flatpickr-current-month span.cur-month {
  font-weight: 300;
}
[dir] .flatpickr-time input:hover, [dir] .flatpickr-time .flatpickr-am-pm:hover, [dir] .flatpickr-time input:focus, [dir] .flatpickr-time .flatpickr-am-pm:focus {
  background: #fff;
}
[dir] .dark-layout .flatpickr-calendar {
  background: #161d31;
  border-color: #161d31;
  box-shadow: none;
}
.dark-layout .flatpickr-calendar .flatpickr-months i,
.dark-layout .flatpickr-calendar .flatpickr-months svg {
  fill: #b4b7bd;
}
.dark-layout .flatpickr-calendar .flatpickr-month {
  color: #b4b7bd;
}
[dir=ltr] .dark-layout .flatpickr-calendar .flatpickr-weekwrapper .flatpickr-weeks {
  box-shadow: 1px 0 0 #3b4253;
}
[dir=rtl] .dark-layout .flatpickr-calendar .flatpickr-weekwrapper .flatpickr-weeks {
  box-shadow: -1px 0 0 #3b4253;
}
.dark-layout .flatpickr-calendar .flatpickr-weekday {
  color: #b4b7bd;
}
.dark-layout .flatpickr-calendar .flatpickr-day, .dark-layout .flatpickr-calendar .flatpickr-day.today:hover {
  color: #b4b7bd;
}
.dark-layout .flatpickr-calendar .flatpickr-day.selected {
  color: #fff;
}
.dark-layout .flatpickr-calendar .flatpickr-day.prevMonthDay, .dark-layout .flatpickr-calendar .flatpickr-day.nextMonthDay, .dark-layout .flatpickr-calendar .flatpickr-day.flatpickr-disabled {
  color: #4e5154 !important;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-day.inRange, [dir] .dark-layout .flatpickr-calendar .flatpickr-day.inRange:hover {
  background: #283046;
  border-color: #283046;
}
[dir=ltr] .dark-layout .flatpickr-calendar .flatpickr-day.inRange, [dir=ltr] .dark-layout .flatpickr-calendar .flatpickr-day.inRange:hover {
  box-shadow: -5px 0 0 #283046, 5px 0 0 #283046;
}
[dir=rtl] .dark-layout .flatpickr-calendar .flatpickr-day.inRange, [dir=rtl] .dark-layout .flatpickr-calendar .flatpickr-day.inRange:hover {
  box-shadow: 5px 0 0 #283046, -5px 0 0 #283046;
}
.dark-layout .flatpickr-calendar .flatpickr-day:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  color: #b4b7bd;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-day:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  border-color: #283046;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-days .flatpickr-day:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  background: #283046;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-time {
  border-color: #161d31 !important;
}
.dark-layout .flatpickr-calendar .flatpickr-time .numInput,
.dark-layout .flatpickr-calendar .flatpickr-time .flatpickr-am-pm {
  color: #b4b7bd;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-time .numInput:hover, [dir] .dark-layout .flatpickr-calendar .flatpickr-time .flatpickr-am-pm:hover {
  background: #161d31;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-time .arrowUp:after {
  border-bottom-color: #b4b7bd;
}
[dir] .dark-layout .flatpickr-calendar .flatpickr-time .arrowDown:after {
  border-top-color: #b4b7bd;
}
[dir] .dark-layout .flatpickr-time input:hover, [dir] .dark-layout .flatpickr-time .flatpickr-am-pm:hover, [dir] .dark-layout .flatpickr-time input:focus, [dir] .dark-layout .flatpickr-time .flatpickr-am-pm:focus {
  background: #161d31;
}
.flatpickr-input[readonly],
.flatpickr-input ~ .form-control[readonly],
.flatpickr-human-friendly[readonly] {
  opacity: 1 !important;
}
[dir] .flatpickr-input[readonly], [dir] .flatpickr-input ~ .form-control[readonly], [dir] .flatpickr-human-friendly[readonly] {
  background-color: inherit;
}
[dir] .flatpickr-weekdays {
  margin-top: 8px;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  -webkit-appearance: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month .numInputWrapper {
  font-size: 1.1rem;
  transition: all 0.15s ease-out;
}
[dir] .flatpickr-current-month .flatpickr-monthDropdown-months, [dir] .flatpickr-current-month .numInputWrapper {
  border-radius: 4px;
  padding: 2px;
}
.flatpickr-current-month .flatpickr-monthDropdown-months span,
.flatpickr-current-month .numInputWrapper span {
  display: none;
}
html[dir=rtl] .flatpickr-calendar .flatpickr-prev-month svg,
html[dir=rtl] .flatpickr-calendar .flatpickr-next-month svg {
  transform: rotate(180deg);
}